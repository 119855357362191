.mat-column-id {
  max-width: 86px;
}

.mat-column-acoes {
  max-width: 150px;
}

@page{
 size: auto;
 margin: 0mm;
}

.css-impressao {
  -webkit-print-color-adjust: exact;
}
